<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm6 md4 lg3>
            <v-form ref="form" @submit.prevent="login()">
              <v-card darks class="elevation-3 sign-boxe">
                <v-card-text>
                  <div class="text-center">
                    <img
                      v-bind:src="require('../assets/minedu_v.png')"
                      alt="Logo"
                      width="140"
                    />
                  </div>
                  <v-card-title class="layout column align-center">
                    <!-- <h3>AUTENTICACIÓN</h3> -->
                    <h5 class="red--text text--darken-1">
                      SISTEMA DE GESTIÓN DGFM
                    </h5>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-text-field
                    outlined
                    append-icon="mdi-account"
                    name="usuario"
                    label="Usuario"
                    type="text"
                    v-model="user.username"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    append-icon="mdi-lock"
                    name="password"
                    label="Contraseña"
                    id="password"
                    type="password"
                    v-model="user.password"
                    :rules="[(v) => !!v || 'El campo es requerido']"
                    autocomplete="off"
                  ></v-text-field>
                  <span
                    class="red--text font-weight-medium"
                    v-if="message != ''"
                  >
                    {{ message }}
                  </span>
                </v-card-text>
                <v-card-actions class="pt-0 pa-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    type="submit"
                    color="success"
                    large
                    outlineds
                    :loading="loading"
                  >
                    <v-icon>mdi-check</v-icon>Ingresar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Service from "../services/general";
import axios from "axios";
export default {
  data: () => ({
    loading: false,
    user: {
      username: "",
      password: "",
    },
    platforms: [],
    message: "",
  }),
  created() {
    this.logout();
  },
  mounted() {},
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("login", this.user)
          .then((response) => {
            this.loading = false;
            if (response.status == "error") {
              this.message = response.msg;
            } else {
              this.$router.push("/inicio");
            }
          })
          .catch((err) => {
            this.loading = false;
            this.message = err.msg;
          });
      }
    },
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        Service.removeUser();
        // this.$router.push("/autenticacion");
      });
    },
  },
};
</script>
<style scoped lang="css">
#login {
  background-color: #fafafa;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='29' height='29' fill-opacity='0.4' fill='%23EEEEEE'/%3E%3C/svg%3E");
}
</style>
